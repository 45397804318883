import { Controller } from "stimulus"

let merch = {};
export default class extends Controller {
    static targets = [ 'componentPath', 'payment', 'payload', 'street1', 'country', 'city', 'state', 'zip' ]

    connect() {
        if (this.hasPayloadTarget) {
            const payload = this.payloadTarget.value;
            const address = {
                "street": this.street1Target.value,
                "city": this.cityTarget.value,
                "stateOrProvince": this.stateTarget.value,
                "postalCode": this.zipTarget.value,
                "country": this.countryTarget.value,
            }
            if (payload.length > 0) {
                renderPaymentComponent(payload, address)
            } else {
                renderError();
            }
        }
    }
}

function renderPaymentComponent(payload, address) {
    const paymentComponent = document.querySelector('#payment-component');
    merch = new TessituraMerchantServices.PaymentComponent({
        "payload": payload,
        "showPayButton": true,
        "enableStoredCards": true,
        "endpoints": {
            "authorize": "/user/my_wallet/create",
            "finalize": "/user/my_wallet/finalize"
        },
        "showAddress": 'full',
        "billingAddress": {
            "street": address.street,
            "city": address.city,
            "stateOrProvince": address.stateOrProvince,
            "postalCode": address.postalCode,
            "country": address.country
        },
        "locale": "en-US",
    });
    merch.mount(paymentComponent);
}

function renderError() {
    const payment = document.querySelector('#payment-component');
    payment.classList.add('error');
    payment.innerHTML = 'There was an error loading the payment component. Please try again.';
}